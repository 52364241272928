import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  updateDoc,
} from "firebase/firestore";
import { db, storage } from "./firebaseConfig";
import {
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
  ref,
  deleteObject,
} from "firebase/storage";

export const getVideos = async () => {
  try {
    const docRef = doc(db, "youtubeLinks", "you-tube-links"); // Replace 'your-document-id' with the actual document ID
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      return docSnap.data();
    } else {
      console.log("No such document!");
    }
  } catch (error) {
    console.error("Error fetching document:", error);
  }
};

export const getCampaings = async () => {
  try {
    const docRef = doc(db, "campaings", "campaings");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      return docSnap.data();
    } else {
      console.log("No such document!");
    }
  } catch (error) {
    console.error("Error fetching document:", error);
  }
};

export const getResults = async () => {
  try {
    const docRef = doc(db, "results", "resultsPhoto");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      return docSnap.data();
    } else {
      console.log("No such document!");
    }
  } catch (error) {
    console.error("Error fetching document:", error);
  }
};

export const getTeachers = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, "teachersDetails"));
    const teachers = []; // Create an array to store the data

    querySnapshot.forEach((doc) => {
      teachers.push({ id: doc.id, ...doc.data() }); // Add each document's data to the array
    });
    return teachers; // Return the array
  } catch (error) {
    console.error("Error fetching documents: ", error);
    throw error; // Optionally re-throw the error for better error handling
  }
};

export const getSchoolTeachers = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, "schoolTeachers"));
    const teachers = []; // Create an array to store the data

    querySnapshot.forEach((doc) => {
      teachers.push({ id: doc.id, ...doc.data() }); // Add each document's data to the array
    });
    return teachers; // Return the array
  } catch (error) {
    console.error("Error fetching documents: ", error);
    throw error; // Optionally re-throw the error for better error handling
  }
};

export const getTutors = async () => {
  try {
    // Create a query with ordering by regDate in descending order
    const tutorsQuery = query(collection(db, "pretutors"), orderBy("regDate", "desc"));

    const querySnapshot = await getDocs(tutorsQuery);
    const teachers = []; // Create an array to store the data

    querySnapshot.forEach((doc) => {
      teachers.push({ id: doc.id, ...doc.data() }); // Add each document's data to the array
    });
    return teachers; // Return the array
  } catch (error) {
    console.error("Error fetching documents: ", error);
    throw error; // Optionally re-throw the error for better error handling
  }
};

export const getJobPlacements = async () => {
  try {
    // Create a query with ordering by regDate in descending order
    const tutorsQuery = query(collection(db, "preJob"), orderBy("regDate", "desc"));

    const querySnapshot = await getDocs(tutorsQuery);
    const teachers = []; // Create an array to store the data

    querySnapshot.forEach((doc) => {
      teachers.push({ id: doc.id, ...doc.data() }); // Add each document's data to the array
    });
    return teachers; // Return the array
  } catch (error) {
    console.error("Error fetching documents: ", error);
    throw error; // Optionally re-throw the error for better error handling
  }
};

const uploadFile = async (file, storagePath) => {
  const fileRef = storageRef(storage, storagePath);
  await uploadBytes(fileRef, file);
  const downloadURL = await getDownloadURL(fileRef);
  return downloadURL;
};

export const submitJobData = async (data) => {
  try {
    const photoURL = await uploadFile(
      data.photo,
      "imagesPlacement/" + `${new Date().getTime()}_${data.photo.name}`
    );
    const idProofURL = await uploadFile(
      data.idProof,
      "idsPlacement/" + `${new Date().getTime()}_${data.idProof.name}`
    );
    const jobData = {
      name: data.name,
      email: data.email,
      referral: data.referral,
      age: data.age,
      gender: data.gender,
      fatherName: data.fatherName,
      mobileNo: data.mobileNo,
      alternateMobileNo: data.alternateMobileNo,
      experience: data.experience,
      currentAddress: data.currentAddress,
      permanentAddress: data.permanentAddress,
      qualification: data.qualification,
      prefferedArea: data.prefferedArea,
      maritalStatus: data.maritalStatus,
      regDate: data.regDate,
      photo: photoURL,
      idProof: idProofURL,
      profile: data.profile,
      salaryExpected: data.salaryExpected,
    };

    const docRef = await addDoc(collection(db, "preJob"), jobData);
    console.log("Document written with ID: ", docRef.id);
  } catch (error) {
    console.error("Error submitting data:", error);
  }
};

export const submitTutorsData = async (data) => {
  try {
    const photoURL = await uploadFile(
      data.photo,
      "images/" + `${new Date().getTime()}_${data.photo.name}`
    );
    const idProofURL = await uploadFile(
      data.idProof,
      "ids/" + `${new Date().getTime()}_${data.idProof.name}`
    );
    const jobData = {
      address: data.address,
      age: data.age,
      alternateMobileNo: data.alternateMobileNo,
      areaVisit: data.areaVisit,
      declaration: data.declaration,
      email: data.email,
      engComm: data.engComm ? "Yes" : "No",
      experience: data.experience,
      fees: data.fees,
      gender: data.gender,
      idProof: idProofURL,
      maritalStatus: data.maritalStatus,
      mobileNo: data.mobileNo,
      name: data.name,
      photo: photoURL,
      qualification: data.qualification,
      refrence: data.refrence,
      regDate: data.regDate,
      subjects: data.subjects,
      transportMode: data.transportMode,
    };
    const docRef = await addDoc(collection(db, "pretutors"), jobData);
    console.log("Document written with ID: ", docRef.id);
  } catch (error) {
    console.error("Error submitting data:", error);
  }
};

export const submitInstituteTeacher = async (data) => {
  try {
    const photoURL = await uploadFile(
      data.photo,
      "teachers/" + `${new Date().getTime()}_${data.photo.name}`
    );
    const jobData = {
      photo: photoURL,
      name: data.name,
      experience: data.experience,
      qualification: data.qualification,
      subjects: data.subjects,
    };

    const docRef = await addDoc(collection(db, "teachersDetails"), jobData);
    console.log("Document written with ID: ", docRef.id);
  } catch (error) {
    console.error("Error submitting data:", error);
  }
};

export const editInstituteTeacher = async (teacherId, updatedData) => {
  console.log(teacherId, updatedData);
  try {
    // Get reference to the document
    const teacherRef = doc(db, "teachersDetails", teacherId);

    // Fetch existing document data
    const teacherDoc = await getDoc(teacherRef);
    if (!teacherDoc.exists()) {
      throw new Error("Teacher not found");
    }

    let photoURL = teacherDoc.data().photo; // Keep the existing photo by default

    // If a new photo is provided, upload it
    if (updatedData.photo && updatedData.photo instanceof File) {
      photoURL = await uploadFile(
        updatedData.photo,
        "teachers/" + `${new Date().getTime()}_${updatedData.photo.name}`
      );
    }

    // Prepare updated data
    const updateData = {
      name: updatedData.name,
      experience: updatedData.experience,
      qualification: updatedData.qualification,
      subjects: updatedData.subjects,
      photo: photoURL, // Update only if a new photo is uploaded
    };

    // Update the Firestore document
    await updateDoc(teacherRef, updateData);
    console.log("Document successfully updated");
  } catch (error) {
    console.error("Error updating data:", error);
  }
};

export const softDeleteTeacher = async (teacherId) => {
  try {
    const teacherRef = doc(db, "teachersDetails", teacherId);
    await updateDoc(teacherRef, { isDeleted: true });
    console.log(`Teacher ${teacherId} marked as deleted.`);
  } catch (error) {
    console.error("Error soft deleting teacher:", error);
  }
};

export const submitSchoolTeacher = async (data) => {
  try {
    const photoURL = await uploadFile(
      data.photo,
      "schoolTeachers/" + `${new Date().getTime()}_${data.photo.name}`
    );
    const jobData = {
      photo: photoURL,
      name: data.name,
      experience: data.experience,
      qualification: data.qualification,
      subjects: data.subjects,
    };

    const docRef = await addDoc(collection(db, "schoolTeachers"), jobData);
    console.log("Document written with ID: ", docRef.id);
  } catch (error) {
    console.error("Error submitting data:", error);
  }
};

export const editSchoolTeacher = async (teacherId, updatedData) => {
  try {
    // Get reference to the document
    const teacherRef = doc(db, "schoolTeachers", teacherId);

    // Fetch existing document data
    const teacherDoc = await getDoc(teacherRef);
    if (!teacherDoc.exists()) {
      throw new Error("Teacher not found");
    }

    let photoURL = teacherDoc.data().photo; // Keep the existing photo by default

    // If a new photo is provided, upload it
    if (updatedData.photo && updatedData.photo instanceof File) {
      photoURL = await uploadFile(
        updatedData.photo,
        "schoolTeachers/" + `${new Date().getTime()}_${updatedData.photo.name}`
      );
    }

    // Prepare updated data
    const updateData = {
      name: updatedData.name,
      experience: updatedData.experience,
      qualification: updatedData.qualification,
      subjects: updatedData.subjects,
      photo: photoURL, // Update only if a new photo is uploaded
    };

    // Update the Firestore document
    await updateDoc(teacherRef, updateData);
    console.log("Document successfully updated");
  } catch (error) {
    console.error("Error updating data:", error);
  }
};

export const softDeleteSchoolTeacher = async (teacherId) => {
  try {
    const teacherRef = doc(db, "schoolTeachers", teacherId);
    await updateDoc(teacherRef, { isDeleted: true });
    console.log(`Teacher ${teacherId} marked as deleted.`);
  } catch (error) {
    console.error("Error soft deleting teacher:", error);
  }
};

export const addResultImage = async (imageFile) => {
  console.log("imageFile", imageFile, imageFile?.photo.name);
  try {
    // Upload image to Firebase Storage
    const imageURL = await uploadFile(
      imageFile.photo,
      `results/${new Date().getTime()}_${imageFile.photo.name}`
    );

    // Get document reference
    const docRef = doc(db, "results", "resultsPhoto");

    // Update Firestore document (add new image URL)
    await updateDoc(docRef, {
      data: arrayUnion(imageURL),
    });

    console.log("Image added successfully:", imageURL);
  } catch (error) {
    console.error("Error adding image:", error);
  }
};

const deleteFile = async (fileURL) => {
  try {
    const fileRef = ref(storage, fileURL);

    await deleteObject(fileRef);
    console.log("File deleted from Storage:", fileURL);
  } catch (error) {
    console.error("Error deleting file from Storage:", error);
  }
};

export const deleteResultImage = async (imageURL) => {
  try {
    const docRef = doc(db, "results", "resultsPhoto");

    // Remove the file from Firebase Storage
    await deleteFile(imageURL);

    // Remove the URL from Firestore
    await updateDoc(docRef, {
      data: arrayRemove(imageURL),
    });

    console.log("Image deleted successfully:", imageURL);
  } catch (error) {
    console.error("Error deleting image:", error);
  }
};

export const addCampaignImage = async (imageFile) => {
  try {
    // Upload image to Firebase Storage
    const imageURL = await uploadFile(
      imageFile.photo,
      `campaings/${new Date().getTime()}_${imageFile.photo.name}`
    );

    // Get document reference
    const docRef = doc(db, "campaings", "campaings");

    // Update Firestore document (add new image URL)
    await updateDoc(docRef, {
      data: arrayUnion(imageURL),
    });

    console.log("Image added successfully:", imageURL);
  } catch (error) {
    console.error("Error adding image:", error);
  }
};

export const deletCampaignImage = async (imageURL) => {
  try {
    const docRef = doc(db, "campaings", "campaings");

    // Remove the file from Firebase Storage
    await deleteFile(imageURL);

    // Remove the URL from Firestore
    await updateDoc(docRef, {
      data: arrayRemove(imageURL),
    });

    console.log("Image deleted successfully:", imageURL);
  } catch (error) {
    console.error("Error deleting image:", error);
  }
};

export const addYoutubeLink = async (url) => {
  try {
    // Get document reference
    const docRef = doc(db, "youtubeLinks", "you-tube-links");
    await updateDoc(docRef, {
      data: arrayUnion(url),
    });

    console.log("Url added successfully:", url);
  } catch (error) {
    console.error("Error adding image:", error);
  }
};

export const deletYoutubeLink = async (url) => {
  try {
    const docRef = doc(db, "youtubeLinks", "you-tube-links");

    // Remove the URL from Firestore
    await updateDoc(docRef, {
      data: arrayRemove(url),
    });

    console.log("Image deleted successfully:", url);
  } catch (error) {
    console.error("Error deleting image:", error);
  }
};
