import Container from "@mui/material/Container";
import Icon from "@mui/material/Icon";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import typography from "assets/theme/base/typography";
function SimpleFooter() {
  const { size } = typography;
  return (
    <Container>
      <MKBox
        width="100%"
        display="flex"
        flexDirection={{ xs: "column", lg: "row" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <MKBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          color={"white"}
          fontSize={size.sm}
        >
          &copy; 2021, made with
          <MKBox fontSize={size.md} color={"white"} mb={-0.5} mx={0.25}>
            <Icon color="inherit" fontSize="inherit">
              favorite
            </Icon>
          </MKBox>
          by
          <MKTypography variant="button" fontWeight="medium" color={"white"}>
            &nbsp;Md Tarvez Ansari&nbsp;
          </MKTypography>
          for a better web.
        </MKBox>
      </MKBox>
    </Container>
  );
}
export default SimpleFooter;
