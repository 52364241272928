/* eslint-disable */
import {
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Drawer,
  FormControl,
  Grid,
  Typography,
  FormHelperText,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import Lottie from "lottie-react";
import trash_animation from "components/TrashAnimation/trash_animation.json";
import { useFormik } from "formik";
import * as Yup from "yup";
import { addYoutubeLink } from "firestoreService";
import { deletYoutubeLink } from "firestoreService";
import { getVideos } from "firestoreService";
import ReactPlayer from "react-player";

const YouTube = () => {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deteteData, setDeleteData] = useState(null);
  const [render, setRender] = useState(false);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      link: "",
    },

    validationSchema: Yup.object().shape({
      link: Yup.string().required("Link is required"),
    }),

    onSubmit: async (values) => {
      setLoader(true);
      console.log(values.link);
      await addYoutubeLink(values.link);
      validation.resetForm();
      handleClose();
      setLoader(false);
      alert("Added Successfully");
      setRender(!render);
    },
  });

  const handleClose = () => {
    validation.resetForm();
    setOpen(false);
  };

  const getVideosList = async () => {
    setLoader(true);
    const res = await getVideos();
    console.log(res?.data);
    setData(res?.data);
    setLoader(false);
  };

  useEffect(() => {
    getVideosList();
  }, [render]);

  const handleDelete = (tutor) => {
    setDeleteModal(true);
    setDeleteData(tutor);
  };

  const handleDeleteClose = () => {
    setDeleteModal(false);
    setDeleteData(null);
  };

  const handleSubmitDelete = async () => {
    setLoader(true);
    await deletYoutubeLink(deteteData);
    handleDeleteClose();
    setLoader(false);
    alert("Deleted Successfully");
    setRender(!render);
  };

  return (
    <Box sx={{ p: 3, bgcolor: "#f9f9f9", height: "100vh", maxWidth: "1300px" }}>
      <Box sx={{ backgroundColor: "#fff", p: 2, borderRadius: "10px" }}>
        <Typography
          sx={{
            fontSize: "1.8rem",
            fontWeight: "bold",
            color: "#333",
            mb: 2,
          }}
        >
          Youtube
        </Typography>
      </Box>
      <Box>
        {loader ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 600,
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            <Button
              variant="contained"
              color="primary"
              sx={{
                fontSize: "0.875rem",
                textTransform: "none",
                color: "#fff",
                float: "right",
              }}
              onClick={() => setOpen(true)}
            >
              <AddIcon sx={{ mr: 1 }} /> Add
            </Button>
            <Grid item xs={12} lg={9} sx={{ mt: 2 }}>
              <Grid container spacing={3}>
                {data.map((item) => (
                  <Grid item xs={12} md={4} sm={4} lg={4} xl={4} sx={{ mb: 2 }} key={item}>
                    <Card
                      sx={{
                        position: "relative",
                        opacity: 1,
                        background: "#fff",
                        color: "#344767",
                        p: 2,
                        borderRadius: "0.75rem",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "center",
                          mr: -2,
                          mb: 1,
                        }}
                      >
                        <DeleteForeverIcon
                          sx={{ mr: 2, color: "red", fontSize: "1rem", cursor: "pointer" }}
                          onClick={() => handleDelete(item)}
                        />
                      </Box>
                      <Box
                        sx={{
                          minHeight: "10rem",
                          opacity: 1,
                          background: "rgb(255, 255, 255)",
                          color: "rgb(52, 71, 103)",
                          borderRadius: "0.75rem",
                          boxShadow:
                            "rgba(0, 0, 0, 0.1) 0rem 0.625rem 0.9375rem -0.1875rem, rgba(0, 0, 0, 0.05) 0rem 0.25rem 0.375rem -0.125rem",
                          overflow: "hidden",
                          transform: "perspective(999px) rotateX(0deg) translate3d(0px, 0px, 0px)",
                          transformOrigin: "50% 0px",
                          backfaceVisibility: "hidden",
                          willChange: "transform, box-shadow",
                          transition: "transform 200ms ease-out",
                        }}
                      >
                        <ReactPlayer url={item} controls width="100%" height={250} />
                      </Box>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Box>
        )}
        <Box sx={{ display: "flex" }}>
          <Backdrop
            open={open}
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1200,
            }}
          />
          <Drawer anchor="right" open={open} onClose={handleClose}>
            <Box sx={{ width: 400 }}>
              <Box className="main_header">
                <Box className="add_lead">{"Add Videos Link"}</Box>
                <Box>
                  <CloseIcon className="close" onClick={handleClose} />
                </Box>
              </Box>
              <Box sx={{ p: 2 }}>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  {loader ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Box>
                      <FormControl fullWidth>
                        <Typography sx={{ fontSize: "0.875rem", fontWeight: 700 }}>
                          Link<span style={{ color: "red", marginLeft: 3 }}>*</span>
                        </Typography>
                        <TextField
                          size="small"
                          name="link"
                          type="text"
                          value={validation.values.link}
                          onChange={validation.handleChange}
                          error={validation.touched.link && validation.errors.link}
                        />
                        {validation.touched.link && validation.errors.link ? (
                          <FormHelperText sx={{ color: "red" }}>
                            {validation.errors.link}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                      <Divider sx={{ mt: 5, width: "100%" }} />
                      <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        sx={{ mt: 2, color: "#fff" }}
                      >
                        Submit
                      </Button>
                    </Box>
                  )}
                </form>
              </Box>
            </Box>
          </Drawer>
        </Box>
        <Dialog open={deleteModal}>
          <Box
            sx={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              paddingTop: "13px",
              paddingRight: "13px",
            }}
          >
            <CloseIcon sx={{ fontSize: "25px", cursor: "pointer" }} onClick={handleDeleteClose} />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: " 0 30px 30px 30px",
            }}
          >
            <Box sx={{ padding: " 20px 0px" }}>
              <Box sx={{ height: "150px", width: "150px" }}>
                <Lottie animationData={trash_animation} loop={true} />
              </Box>
            </Box>
            <DialogTitle
              sx={{
                textAlign: "center",
                fontWeight: "semi-bold",
                padding: "10px 0px",
              }}
              id="alert-dialog-title"
            >
              {"Do you want to Delete ?"}
            </DialogTitle>
            <DialogActions sx={{ justifyContent: "center", width: "100%" }}>
              <Button
                onClick={handleDeleteClose}
                variant="outlined"
                sx={{
                  color: "#0c203b",
                  borderColor: "#0c203b",
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleSubmitDelete}
                sx={{
                  color: "#FFF",
                }}
              >
                Yes, Delete It!!
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      </Box>
    </Box>
  );
};

export default YouTube;
