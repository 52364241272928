import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import MKTypography from "components/MKTypography";
import logoCT from "assets/images/logo-ct-dark.png";

export default {
  brand: {
    name: "A1 Home Tutors",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/a1institute.in?mibextid=ZbWKwL",
    },
    {
      icon: <TwitterIcon />,
      link: "https://a1hometutors.com/",
    },
    {
      icon: <InstagramIcon />,
      link: "https://www.instagram.com/a1playschool/?igshid=YmMyMTA2M2Y%3D",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/@a1hometutors553/videos",
    },
  ],
  menus: [
    {
      name: "A1 Institute",
      items: [
        { name: "about us", href: "/about-us" },
        { name: "results", href: "/results" },
        { name: "teachers", href: "/teachers" },
      ],
    },
    {
      name: "A1 Play School",
      items: [
        { name: "abouts us", href: "/a1playschool/about-us" },
        { name: "teachers", href: "/a1playschool/teachers" },
      ],
    },
    {
      name: "A1 Job Placement",
      items: [{ name: "registration", href: "/job-placement" }],
    },
    {
      name: "A1 Home Tutors",
      items: [
        { name: "about us", href: "/about-us" },
        { name: "teacher registration", href: "/tutor-registration" },
        { name: "contact us", href: "/contact-us" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      Copyright © 2021 A1 Home Tutor | Designed by
      <MKTypography
        component="a"
        href="https://www.creative-tim.com"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        &nbsp; Md Tarvez Ansari, Mob: +91 8750653833
      </MKTypography>
      .
    </MKTypography>
  ),
};
