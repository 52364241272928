import TutorRegistration from "pages/TutorRegistration";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import JobPlacement from "pages/JobPlacement";
import Teachers from "pages/Teachers";
import Results from "pages/Results";
import Campaign from "pages/Campaing";
import SchoolTeacher from "pages/SchoolTeacher";
import SchoolAboutUs from "pages/SchoolAboutUs";

const routes = [
  {
    name: "Tutor Registration",
    route: "/tutor-registration",
    component: <TutorRegistration />,
  },
  {
    name: "Job Placement",
    route: "/job-placement",
    component: <JobPlacement />,
  },
  {
    name: "Teachers",
    collapse: [
      {
        name: "A1 Institute Teachers",
        route: "/teachers",
        component: <Teachers />,
      },
      {
        name: "A1 Play School Teachers",
        route: "/a1playschool-teacher",
        component: <SchoolTeacher />,
      },
    ],
  },
  {
    name: "Results",
    route: "/results",
    component: <Results />,
  },
  {
    name: "Campaign",
    route: "/campaign",
    component: <Campaign />,
  },
  {
    name: "About Us",
    collapse: [
      {
        name: "A1 Institute About Us",
        route: "/about-us",
        component: <AboutUs />,
      },
      {
        name: "A1 Play School About Us",
        route: "/a1playschool-about-us",
        component: <SchoolAboutUs />,
      },
    ],
  },
  {
    name: "Contact Us",
    route: "/contact-us",
    component: <ContactUs />,
  },
];

export default routes;
