import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Drawer,
  FormControl,
  FormHelperText,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as Yup from "yup";
import imageCompression from "browser-image-compression";
import Lottie from "lottie-react";
import trash_animation from "components/TrashAnimation/trash_animation.json";
import { submitInstituteTeacher } from "firestoreService";
import { editInstituteTeacher } from "firestoreService";
import { softDeleteTeacher } from "firestoreService";
import PropTypes from "prop-types";

const InstituteTeachers = (props) => {
  const { data, reload } = props;
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [imagePreview, setImagePreview] = useState("/Portrait_Placeholder.png");
  const [selectedTutor, setSelectedTutor] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deteteData, setDeleteData] = useState(null);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      photo: selectedTutor?.photo || null,
      name: selectedTutor?.name || "",
      experience: selectedTutor?.experience || "",
      qualification: selectedTutor?.qualification || "",
      subjects: selectedTutor?.subjects || "",
    },

    validationSchema: Yup.object().shape({
      photo: isEdit
        ? Yup.mixed() // No validation when editing
        : Yup.mixed()
            .required("Photo is required")
            .test(
              "fileSize",
              "File size is too large (max 100KB)",
              (value) => !value || (value && value.size <= 102400)
            )
            .test(
              "fileType",
              "Unsupported file format (only png, jpg, jpeg, webp)",
              (value) =>
                !value ||
                (value &&
                  ["image/png", "image/jpg", "image/jpeg", "image/webp"].includes(value.type))
            ),
      name: Yup.string().required("Name is required"),
      experience: Yup.number()
        .min(0, "Experience must be at least 0")
        .required("Experience is required"),
      qualification: Yup.string().required("Qualification is required"),
      subjects: Yup.string().required("Subjects or Class is required"),
    }),

    onSubmit: async (values) => {
      setSubmitLoader(true);
      console.log(values);
      if (isEdit) {
        await editInstituteTeacher(selectedTutor.id, values);
      } else {
        await submitInstituteTeacher(values);
      }
      validation.resetForm();
      setImagePreview("/Portrait_Placeholder.png");
      handleClose();
      setSubmitLoader(false);
      alert("Submit Successfully");
      reload();
    },
  });

  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      // Check if the file is an image
      const isImage = ["image/png", "image/jpg", "image/jpeg", "image/webp"].includes(file.type);
      if (!isImage) {
        console.error("Unsupported file type. Please upload a valid image.");
        validation.setFieldError(
          "photo",
          "Unsupported file format. Please upload png, jpg, jpeg, or webp."
        );
        return;
      }

      let processedFile = file;

      // Compress image if it exceeds 100KB
      if (file.size > 102400) {
        try {
          const options = {
            maxSizeMB: 0.1, // 0.1 MB = 100 KB
            maxWidthOrHeight: 800, // Optional resizing
            useWebWorker: true,
          };
          processedFile = await imageCompression(file, options);
          console.log("Image compressed successfully:", processedFile);
        } catch (error) {
          console.error("Error compressing image:", error);
          validation.setFieldError("photo", "Error compressing the image. Please try again.");
          return;
        }
      }

      // Set the processed file in Formik
      validation.setFieldValue("photo", processedFile);

      // Generate preview
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(processedFile);
    }
  };

  const handleClose = () => {
    setImagePreview("/Portrait_Placeholder.png");
    setSelectedTutor(null);
    validation.resetForm();
    setOpen(false);
    setIsEdit(false);
  };

  const handleEdit = (tutor) => {
    setSelectedTutor(tutor);
    setImagePreview(tutor?.photo);
    setOpen(true);
    setIsEdit(true);
  };

  const handleDelete = (tutor) => {
    setDeleteModal(true);
    setDeleteData(tutor);
  };

  const handleDeleteClose = () => {
    setDeleteModal(false);
    setDeleteData(null);
  };

  const handleSubmitDelete = async () => {
    setSubmitLoader(true);
    await softDeleteTeacher(deteteData.id);
    handleDeleteClose();
    setSubmitLoader(false);
    alert("Deleted Successfully");
    reload();
  };

  useEffect(() => {
    validation.setValues({
      photo: selectedTutor?.photo || null,
      name: selectedTutor?.name || "",
      experience: selectedTutor?.experience || "",
      qualification: selectedTutor?.qualification || "",
      subjects: selectedTutor?.subjects || "",
    });
    setImagePreview(selectedTutor?.photo || "/Portrait_Placeholder.png");
  }, [selectedTutor]);

  return (
    <Box>
      <Button
        variant="contained"
        color="primary"
        sx={{
          fontSize: "0.875rem",
          textTransform: "none",
          color: "#fff",
          float: "right",
        }}
        onClick={() => setOpen(true)}
      >
        <AddIcon sx={{ mr: 1 }} /> Add
      </Button>
      <Grid container spacing={2}>
        {data.map(
          (tutor) =>
            !tutor.isDeleted && (
              <Grid item xs={6} key={tutor.id}>
                <Card
                  variant="outlined"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: 2,
                    flexDirection: "row",
                    height: "148px",
                    overflow: "hidden",
                    borderRadius: "20px",
                    border: "1px solid #ddd",
                  }}
                  key={tutor.id}
                >
                  <Avatar
                    src={tutor.photo}
                    alt={tutor.name}
                    sx={{ width: 140, height: "100%", mr: 2, borderRadius: "0px" }}
                  />
                  <Box sx={{ width: "100%" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="h6">{tutor.name}</Typography>
                      <Box>
                        <DriveFileRenameOutlineIcon
                          sx={{ mr: 2, color: "#174276" }}
                          onClick={() => handleEdit(tutor)}
                        />
                        <DeleteForeverIcon
                          sx={{ mr: 2, color: "red" }}
                          onClick={() => handleDelete(tutor)}
                        />
                      </Box>
                    </Box>
                    <Typography variant="body2" color="text.secondary" sx={{ display: "flex" }}>
                      <Typography variant="body2" fontWeight="bold" sx={{ mr: 1 }}>
                        Subjects:{" "}
                      </Typography>
                      {tutor.subjects}
                    </Typography>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <Typography variant="body2" color="text.secondary" sx={{ display: "flex" }}>
                        <Typography variant="body2" fontWeight="bold" sx={{ mr: 1 }}>
                          Expereince:{" "}
                        </Typography>
                        {tutor.experience}
                      </Typography>
                    </Stack>
                    <Chip label={tutor?.qualification} size="small" />
                  </Box>
                </Card>
              </Grid>
            )
        )}
      </Grid>
      <Box sx={{ display: "flex" }}>
        <Backdrop
          open={open}
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1200,
          }}
        />
        <Drawer anchor="right" open={open} onClose={handleClose}>
          <Box sx={{ width: 400 }}>
            <Box className="main_header">
              <Box className="add_lead">{isEdit ? "Edit Teacher" : "Add Teacher"}</Box>
              <Box>
                <CloseIcon className="close" onClick={handleClose} />
              </Box>
            </Box>
            <Box sx={{ p: 2 }}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                {submitLoader ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <Box>
                    <FormControl fullWidth>
                      <Box
                        sx={{
                          textAlign: "left",
                          marginBottom: 2,
                          opacity: 1,
                          background: "transparent",
                          color: "rgb(52, 71, 103)",
                          boxShadow: "none",
                          width: "100px",
                          height: "100px",
                          border: "1px solid rgb(0, 0, 0)",
                        }}
                      >
                        <img
                          src={imagePreview}
                          alt="Uploaded"
                          style={{ width: 100, height: 100 }}
                        />
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: "0.875rem",
                          }}
                        >
                          Upload your photo (max size 100KB and formats: png, jpg, jpeg, webp)
                          <span style={{ color: "red", marginLeft: 3 }}>*</span>
                        </Typography>
                        <input
                          type="file"
                          accept=".png,.jpg,.jpeg,.webp"
                          style={
                            validation.touched.photo && validation.errors.photo
                              ? { marginTop: 8, border: "1px solid red" }
                              : { marginTop: 8 }
                          }
                          onChange={handleImageChange}
                        />
                        {validation.touched.photo && validation.errors.photo ? (
                          <FormHelperText sx={{ color: "red" }}>
                            {validation.errors.photo}
                          </FormHelperText>
                        ) : null}
                      </Box>
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 1 }}>
                      <Typography sx={{ fontSize: "0.875rem", fontWeight: 700 }}>
                        Name<span style={{ color: "red", marginLeft: 3 }}>*</span>
                      </Typography>
                      <TextField
                        size="small"
                        name="name"
                        type="text"
                        value={validation.values.name}
                        onChange={validation.handleChange}
                        error={validation.touched.name && validation.errors.name}
                      />
                      {validation.touched.name && validation.errors.name ? (
                        <FormHelperText sx={{ color: "red" }}>
                          {validation.errors.name}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 1 }}>
                      <Typography sx={{ fontSize: "0.875rem", fontWeight: 700 }}>
                        Experience (in years)<span style={{ color: "red", marginLeft: 3 }}>*</span>
                      </Typography>
                      <TextField
                        size="small"
                        name="experience"
                        type="text"
                        value={validation.values.experience}
                        onChange={validation.handleChange}
                        error={validation.touched.experience && validation.errors.experience}
                      />
                      {validation.touched.experience && validation.errors.experience ? (
                        <FormHelperText sx={{ color: "red" }}>
                          {validation.errors.experience}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 1 }}>
                      <Typography sx={{ fontSize: "0.875rem", fontWeight: 700 }}>
                        Qualification<span style={{ color: "red", marginLeft: 3 }}>*</span>
                      </Typography>
                      <TextField
                        size="small"
                        name="qualification"
                        type="text"
                        value={validation.values.qualification}
                        onChange={validation.handleChange}
                        error={validation.touched.qualification && validation.errors.qualification}
                      />
                      {validation.touched.qualification && validation.errors.qualification ? (
                        <FormHelperText sx={{ color: "red" }}>
                          {validation.errors.qualification}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 1 }}>
                      <Typography sx={{ fontSize: "0.875rem", fontWeight: 700 }}>
                        Class and subjects to be taught
                        <span style={{ color: "red", marginLeft: 3 }}>*</span>
                      </Typography>
                      <TextField
                        size="small"
                        name="subjects"
                        type="text"
                        value={validation.values.subjects}
                        onChange={validation.handleChange}
                        error={validation.touched.subjects && validation.errors.subjects}
                      />
                      {validation.touched.subjects && validation.errors.subjects ? (
                        <FormHelperText sx={{ color: "red" }}>
                          {validation.errors.subjects}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                    <Divider sx={{ mt: 5, width: "100%" }} />
                    <Button
                      fullWidth
                      type="submit"
                      variant="contained"
                      sx={{ mt: 2, color: "#fff" }}
                    >
                      Submit
                    </Button>
                  </Box>
                )}
              </form>
            </Box>
          </Box>
        </Drawer>
      </Box>
      <Dialog open={deleteModal}>
        <Box
          sx={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            paddingTop: "13px",
            paddingRight: "13px",
          }}
        >
          <CloseIcon sx={{ fontSize: "25px", cursor: "pointer" }} onClick={handleDeleteClose} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: " 0 30px 30px 30px",
          }}
        >
          <Box sx={{ padding: " 20px 0px" }}>
            <Box sx={{ height: "150px", width: "150px" }}>
              <Lottie animationData={trash_animation} loop={true} />
            </Box>
          </Box>
          <DialogTitle
            sx={{
              textAlign: "center",
              fontWeight: "semi-bold",
              padding: "10px 0px",
            }}
            id="alert-dialog-title"
          >
            {"Do you want to Delete ?"}
          </DialogTitle>
          <DialogActions sx={{ justifyContent: "center", width: "100%" }}>
            <Button
              onClick={handleDeleteClose}
              variant="outlined"
              sx={{
                color: "#0c203b",
                borderColor: "#0c203b",
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleSubmitDelete}
              sx={{
                color: "#FFF",
              }}
            >
              Yes, Delete It!!
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
};

InstituteTeachers.propTypes = {
  data: PropTypes.object.isRequired,
  reload: PropTypes.func.isRequired,
};
export default InstituteTeachers;
