import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultNavbar from "sections/DefaultNavbar";
import DefaultFooter from "sections/DefaultFooter";
import Counters from "sections/Counters";
import Information from "sections/Information";
import YoutubeVideos from "sections/YoutubeVideos";
import Campaings from "sections/Campaings";
import routes from "routes";
import footerRoutes from "footer.routes";
import bgImage from "assets/images/bg-presentation.webp";

function Home() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "internal",
          route: "/login",
          label: "LOGIN",
          color: "info",
        }}
        sticky
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
          opacity: 1,
        }}
      >
        <MKBox
          sx={{
            minHeight: "90vh",
            width: "100%",
            opacity: 1,
            color: "rgb(52, 71, 103)",
            boxShadow: "none",
            display: "grid",
            placeItems: "center",
            background: "rgba(52, 71, 103, 0.52)",
          }}
        >
          <Container>
            <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
              <MKTypography
                variant="h1"
                color="white"
                mt={-6}
                mb={1}
                sx={({ breakpoints }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: "1.5rem",
                  },
                  [breakpoints.up("lg")]: {
                    fontSize: "3rem",
                  },
                  [breakpoints.up("xl")]: {
                    fontSize: "3rem",
                  },
                })}
              >
                <h1>A1 Home Tutors</h1>
              </MKTypography>
              <MKTypography
                variant="h2"
                color="white"
                textAlign="center"
                px={{ xs: 6, lg: 12 }}
                mt={1}
              >
                ISO 9001:2015 certified | A1 Home Tutors | Tutor Helpline No:09868420099
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Counters />
        <Information />
        <YoutubeVideos />
        <Campaings />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Home;
