import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { useEffect, useState } from "react";
import { getVideos } from "firestoreService";
import { Box, CircularProgress } from "@mui/material";
import ReactPlayer from "react-player";

function YoutubeVideos() {
  const [loader, setLoader] = useState(false);
  const [videos, setVideos] = useState([]);

  const getVideosList = async () => {
    setLoader(true);
    const res = await getVideos();
    console.log(res?.data);
    setVideos(res.data);
    setLoader(false);
  };

  useEffect(() => {
    getVideosList();
  }, []);

  return (
    <MKBox component="section" my={6} py={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKTypography variant="h2" fontWeight="bold">
            Welcome to A1 Home Tutors
          </MKTypography>
          <MKTypography variant="body1" color="text">
            A1 Institute is the foremost educational platform to performing the best ettiquote to
            reforming structural development of Educational Volumes.
          </MKTypography>
        </Grid>
      </Container>
      <Container sx={{ mt: 6 }}>
        <Grid container spacing={3} sx={{ mb: 10 }}>
          <Grid item xs={12} lg={3}>
            <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
              <MKTypography variant="h3" fontWeight="bold" mb={1}>
                A1 Home Tutors Videos
              </MKTypography>
              <MKTypography variant="body2" fontWeight="regular" color="secondary" mb={1} pr={2}>
                Educational videos
              </MKTypography>
            </MKBox>
          </Grid>
          {loader ? (
            <CircularProgress />
          ) : (
            <Grid item xs={12} lg={9}>
              <Grid container spacing={3}>
                {videos.map((item) => (
                  <Grid item xs={12} md={4} sm={4} lg={4} xl={4} sx={{ mb: 2 }} key={item}>
                    <Box
                      sx={{
                        position: "relative",
                        opacity: 1,
                        background: "tranparent",
                        color: "#344767",
                        boxShadow: "none",
                      }}
                    >
                      <Box
                        sx={{
                          minHeight: "10rem",
                          opacity: 1,
                          background: "rgb(255, 255, 255)",
                          color: "rgb(52, 71, 103)",
                          borderRadius: "0.75rem",
                          boxShadow:
                            "rgba(0, 0, 0, 0.1) 0rem 0.625rem 0.9375rem -0.1875rem, rgba(0, 0, 0, 0.05) 0rem 0.25rem 0.375rem -0.125rem",
                          overflow: "hidden",
                          transform: "perspective(999px) rotateX(0deg) translate3d(0px, 0px, 0px)",
                          transformOrigin: "50% 0px",
                          backfaceVisibility: "hidden",
                          willChange: "transform, box-shadow",
                          transition: "transform 200ms ease-out",
                        }}
                      >
                        <ReactPlayer url={item} controls width="100%" height={250} />
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default YoutubeVideos;
