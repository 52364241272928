import React, { useState } from "react";
import { Box } from "@mui/material";
import Sidebar from "./components/Sidebar";
import TutorList from "./components/TutorList";
import JobList from "./components/JobList";
import Teachers from "./components/Teachers";
import Results from "./components/Results";
import Campaigns from "./components/Campaigns";
import YouTube from "./components/Youtube";

const Admin = () => {
  const [active, setActive] = useState("tutor");

  return (
    <Box sx={{ display: "flex", bgcolor: "#f9f9f9", height: "100vh" }}>
      <Box
        sx={{
          position: "sticky",
          top: 0,
          height: "100vh",
          overflowY: "auto",
          bgcolor: "#fff",
          boxShadow: 1,
          zIndex: 1,
        }}
      >
        <Sidebar active={active} setActive={setActive} />
      </Box>
      <Box sx={{ flexGrow: 1, overflowY: "auto" }}>
        {active === "tutor" && <TutorList />}
        {active === "job" && <JobList />}
        {active === "teacher" && <Teachers />}
        {active === "results" && <Results />}
        {active === "campaings" && <Campaigns />}
        {active === "youtube" && <YouTube />}
      </Box>
    </Box>
  );
};

export default Admin;
