import { Box, CircularProgress, Typography } from "@mui/material";
import { getSchoolTeachers } from "firestoreService";
import { getTeachers } from "firestoreService";
import React, { useEffect, useState } from "react";
import InstituteTeachers from "./InstituteTeachers";
import SchoolTeachers from "./SchoolTeachers";

const Teachers = () => {
  const [dataI, setDataI] = useState([]);
  const [dataS, setDataS] = useState([]);
  const [loader, setLoader] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [render, setRender] = useState(false);

  const handleChange = (newValue) => {
    setActiveTab(newValue);
  };

  const getTeachersList = async () => {
    setLoader(true);
    const res = await getTeachers();
    const resS = await getSchoolTeachers();
    console.log(res);
    console.log(resS);
    setDataI(res);
    setDataS(resS);
    setLoader(false);
  };

  useEffect(() => {
    getTeachersList();
  }, [render]);

  return (
    <Box sx={{ p: 3, bgcolor: "#f9f9f9", height: "100vh", maxWidth: "1300px" }}>
      <Box sx={{ backgroundColor: "#fff", p: 2, borderRadius: "10px" }}>
        <Typography
          sx={{
            fontSize: "1.8rem",
            fontWeight: "bold",
            color: "#333",
            mb: 2,
          }}
        >
          Teachers List
        </Typography>
        <Box className="tab-container">
          <Box onClick={() => handleChange(0)} className={`tabs ${activeTab === 0 && "active"}`}>
            A1 Institute Teachers
          </Box>
          <Box onClick={() => handleChange(1)} className={`tabs ${activeTab === 1 && "active"}`}>
            A1 Play School Teachers
          </Box>
        </Box>
      </Box>
      {
        <Box sx={{ mt: 2 }}>
          {loader ? (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              {activeTab === 0 && (
                <InstituteTeachers data={dataI} reload={() => setRender(!render)} />
              )}
              {activeTab === 1 && <SchoolTeachers data={dataS} reload={() => setRender(!render)} />}
            </>
          )}
        </Box>
      }
    </Box>
  );
};

export default Teachers;
