import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultNavbar from "sections/DefaultNavbar";
import DefaultFooter from "sections/DefaultFooter";
import routes from "routes";
import footerRoutes from "footer.routes";
import bgImage from "assets/images/bg-presentation.webp";
import { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { getTeachers } from "firestoreService";

function Teachers() {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);

  const getCampaingsList = async () => {
    setLoader(true);
    const res = await getTeachers();
    console.log(res);
    setData(res);
    setLoader(false);
  };

  useEffect(() => {
    getCampaingsList();
  }, []);

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "internal",
          route: "/login",
          label: "LOGIN",
          color: "info",
        }}
        sticky
      />
      <MKBox
        minHeight="40vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Teachers
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        {loader ? (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box
            sx={{
              opacity: 1,
              background: "transparent",
              color: "rgb(52, 71, 103)",
              boxShadow: "none",
              margin: "auto",
            }}
          >
            <table style={{ width: "fit-content", minWidth: 850 }}>
              <thead>
                <tr>
                  <th>Photo</th>
                  <th>Name</th>
                  <th>Qualification</th>
                  <th>Experience</th>
                  <th>Classes / Subjects / Designation</th>
                </tr>
              </thead>
              <tbody>
                {data.map(
                  (row) =>
                    !row.isDeleted && (
                      <tr key={row.name}>
                        <td>
                          <img src={row.photo} alt="home tutors" width="70px" />
                        </td>
                        <td>{row.name}</td>
                        <td>{row.qualification}</td>
                        <td>{row.experience}</td>
                        <td>{row.subjects}</td>
                      </tr>
                    )
                )}
              </tbody>
            </table>
          </Box>
        )}
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Teachers;
