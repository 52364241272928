import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { useEffect, useState } from "react";
import { getCampaings } from "firestoreService";
import { Box, Card, CircularProgress } from "@mui/material";

function Campaings() {
  const [loader, setLoader] = useState(false);
  const [videos, setVideos] = useState([]);

  const getCampaingsList = async () => {
    setLoader(true);
    const res = await getCampaings();
    console.log(res?.data);
    setVideos(res.data);
    setLoader(false);
  };

  useEffect(() => {
    getCampaingsList();
  }, []);

  return (
    <MKBox component="section">
      <Container sx={{ mt: 1 }}>
        <Grid container spacing={3} sx={{ mb: 10 }}>
          <Grid item xs={12} lg={3}>
            <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
              <MKTypography variant="h3" fontWeight="bold" mb={1}>
                A1 Home Tutors Campaigns
              </MKTypography>
              <MKTypography variant="body2" fontWeight="regular" color="secondary" mb={1} pr={2}>
                Educational Campaigns
              </MKTypography>
            </MKBox>
          </Grid>
          {loader ? (
            <CircularProgress />
          ) : (
            <Grid item xs={12} lg={9}>
              <Grid container spacing={3}>
                {videos.map((item, index) => (
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ mb: 2 }} key={index}>
                    <Card sx={{ borderRadius: "0.75rem", overflow: "hidden", p: 2 }}>
                      <Box
                        sx={{
                          height: "16rem", // Fixed height for uniformity
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={item}
                          alt={`img-${index}`}
                          style={{
                            maxWidth: "100%", // Ensure it doesn't overflow
                            maxHeight: "100%", // Maintain aspect ratio
                            objectFit: "contain", // Show full image without cropping
                          }}
                        />
                      </Box>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Campaings;
