import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultNavbar from "sections/DefaultNavbar";
import DefaultFooter from "sections/DefaultFooter";
import routes from "routes";
import footerRoutes from "footer.routes";
import bgImage from "assets/images/bg-presentation.webp";
import { Typography } from "@mui/material";

function SchoolAboutUs() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "internal",
          route: "/login",
          label: "LOGIN",
          color: "info",
        }}
        sticky
      />
      <MKBox
        minHeight="40vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              A1 PLAY SCHOOL
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Grid container>
          <Grid md={3} lg={3} xl={3}></Grid>
          <Grid xs={12} sm={12} md={6} lg={6} xl={6} sx={{ textAlign: "center" }}>
            <h4>A1 PLAY SCHOOL {"🏫"} Let&apos;s fly your child</h4>
            <h5>(Regd.) ISO 9001:2015 CERTIFIED</h5>
            <Typography sx={{ fontWeight: 400, fontSize: "1rem" }}>
              We follow the early years foundation stage curriculum to ensure a holistic growth in
              children through experimental methods. We introduce activities that urge a child to
              engage, involve, enquire, apply, and learn by doing it themselves. Vaccinated staff,
              CCTV, Sanitizer, and much more. We give top priority to our Children&apos;s safety and
              hygiene. We have activities like role plays, painting, Dress-ups, poem recitation,
              sports competitions, Birthday Celebrations, Festival celebrations, and much more to
              combine learning with play. Ramleela: Role Play done by students to make them aware of
              our great epic Ramayana. Krishna Janmashtami: Students played various activities of
              Lord Krishna and celebrated Lord Krishna&apos;s birthday. Birthday Celebration:
              Birthday of each student is celebrated in a unique way as it is the most important
              event for a child. Fun in Picnic: We take our students to safe and most enjoyable
              places for Picnic each year. Playing Activity: We let our little stars play on various
              types of swings and slides under the supervision of a teacher inside the school as
              well as outside in the park. A1 Play School is totally committed to the holistic
              development of each student to make them confident and smarter. We also provide the
              best daycare facilities for kids in our area at affordable prices.
            </Typography>
            <h5>Result</h5>
            <Typography sx={{ fontWeight: 400, fontSize: "1rem" }}>
              Our fundamental laxal aim is to achieve the zenith of paramount knowledge and spread
              it on a global scale. Today we must cooperate massively to enhance and correlate
              enjoying the amenities of modern educational apparatus. Indeed, we intend to emphasize
              moral laxity among students in the contemporary era. We endeavour our best to uplift
              educational standards by and bound. It is our pledgeful duty to encourage the level of
              high standards of education dynamically present in today&apos;s scientific scenario.
            </Typography>
          </Grid>
          <Grid md={3} lg={3} xl={3}></Grid>
        </Grid>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default SchoolAboutUs;
