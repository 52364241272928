import {
  Box,
  Typography,
  Paper,
  Avatar,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
} from "@mui/material";
import { getJobPlacements } from "firestoreService";
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import Papa from "papaparse";

const JobList = () => {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [row, setRow] = useState(null);
  const [open, setOpen] = useState(false);

  const handleShowId = (row) => {
    setRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setRow(null);
    setOpen(false);
  };

  const downloadCSV = () => {
    // Define column headers
    const csvHeaders = [
      "Name",
      "Mobile No",
      "Alternate Mobile No",
      "Age",
      "Gender",
      "Current Address",
      "Permanent Address",
      "Email",
      "Father Name",
      "Experience",
      "Salary Expected",
      "Marital Status",
      "Qualification",
      "Referral",
      "Reg Date",
      "Preferred Area",
      "Type of Job",
    ];

    // Format data for CSV
    const csvData = data.map((row) => ({
      Name: row.name || "N/A",
      "Mobile No": row.mobileNo || "N/A",
      "Alternate Mobile No": row.alternateMobileNo || "N/A",
      Age: row.age || "N/A",
      Gender: row.gender || "N/A",
      "Current Address": row.currentAddress || "N/A",
      "Permanent Address": row.permanentAddress || "N/A",
      Email: row.email || "N/A",
      "Father Name": row.fatherName || "N/A",
      Experience: row.experience || "N/A",
      "Salary Expected": row.salaryExpected || "N/A",
      "Marital Status": row.maritalStatus || "N/A",
      Qualification: row.qualification || "N/A",
      Referral: row.refferal || "N/A",
      "Reg Date": row.regDate || "N/A",
      "Preferred Area": row.preferredArea || "N/A",
      "Type of Job": row.profile || "N/A",
    }));

    // Convert JSON data to CSV format
    const csv = Papa.unparse({ fields: csvHeaders, data: csvData });

    // Trigger download
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = "JobPlacementData.csv";
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getTutorsList = async () => {
    setLoader(true);
    const res = await getJobPlacements();
    console.log(res);
    setData(res);
    setLoader(false);
  };

  useEffect(() => {
    getTutorsList();
  }, []);

  const renderPreview = () => {
    console.log(row);
    if (
      row.idProof?.includes("pdf") ||
      row.idProof?.includes("docs") ||
      row.idProof?.includes("docx")
    ) {
      return (
        <iframe src={row.idProof} title="PDF Preview" style={{ width: "100%", height: "100%" }} />
      );
    } else if (
      row.idProof.includes("png") ||
      row.idProof.includes("webp") ||
      row.idProof.includes("jpeg") ||
      row.idProof.includes("jpg")
    ) {
      return <img src={row.idProof} alt="ID Proof" style={{ width: "100%", height: "100%" }} />;
    } else {
      return (
        <Typography sx={{ color: "gray", textAlign: "center", padding: 2 }}>
          Cannot preview this file type. Download to view.
        </Typography>
      );
    }
  };

  const paginationModel = { page: 0, pageSize: 10 };

  const columns = [
    {
      field: "photo",
      headerName: "",
      width: 50,
      renderCell: (params) => (
        <Avatar alt="Profile Photo" src={params.row.photo} sx={{ width: 40, height: 40, mt: 0.5 }}>
          {!params.row.photo && params.row.name
            ? params.row.name
                .split(" ")
                .map((word) => word[0])
                .join("")
                .toUpperCase()
            : null}
        </Avatar>
      ),
    },
    { field: "name", headerName: "Name", width: 200 },
    { field: "mobileNo", headerName: "Mobile No", width: 120 },
    { field: "alternateMobileNo", headerName: "Alternate Mobile No", width: 120 },
    {
      field: "age",
      headerName: "Age",
      type: "number",
      width: 100,
    },
    {
      field: "gender",
      headerName: "Gender",
      width: 100,
    },
    {
      field: "currentAddress",
      headerName: "Current Adress",
      width: 250,
    },
    {
      field: "permanentAddress",
      headerName: "Permanent Adress",
      width: 250,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
    },
    {
      field: "fatherName",
      headerName: "Father Name",
      width: 100,
    },
    {
      field: "experience",
      headerName: "Experience",
      width: 100,
    },
    {
      field: "salaryExpected",
      headerName: "Salary Expected",
      width: 150,
    },
    {
      field: "maritalStatus",
      headerName: "Marital Status",
      width: 100,
    },
    {
      field: "qualification",
      headerName: "Qualification",
      width: 150,
    },
    {
      field: "refferal",
      headerName: "Referral",
      width: 150,
    },
    {
      field: "regDate",
      headerName: "Reg Date",
      width: 100,
    },
    {
      field: "prefferedArea",
      headerName: "Preffered Area",
      width: 250,
    },
    {
      field: "profile",
      headerName: "Type of Job",
      width: 250,
    },
    {
      field: "showId",
      headerName: "",
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          sx={{ color: "#FFF", textTransform: "none" }}
          onClick={() => handleShowId(params.row)}
        >
          Show Id
        </Button>
      ),
    },
  ];

  return (
    <Box sx={{ p: 3, bgcolor: "#f9f9f9", height: "100vh", maxWidth: "1300px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#fff",
          p: 2,
          pb: 1,
          pt: 1,
          borderRadius: "10px",
        }}
      >
        <Typography
          sx={{
            fontSize: "1.8rem",
            fontWeight: "bold",
            color: "#333",
            mb: 2,
          }}
        >
          Job Placement List
        </Typography>
        <Button
          variant="contained"
          sx={{ color: "#FFF", textTransform: "none" }}
          onClick={downloadCSV}
        >
          <DownloadIcon sx={{ mr: 1 }} /> Download All Data
        </Button>
      </Box>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          borderRadius: 2,
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          p: 2,
          mt: 2,
        }}
      >
        {loader ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 600,
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            <DataGrid
              rows={data}
              columns={columns}
              initialState={{ pagination: { paginationModel } }}
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                  borderRadius: 2,
                },
                "& .MuiDataGrid-cell": {
                  fontSize: "0.9rem",
                  color: "#555",
                },
                "& .MuiDataGrid-columnHeaders": {
                  bgcolor: "#f5f5f5",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  fontSize: "0.85rem",
                },
                "& .MuiDataGrid-footerContainer": {
                  bgcolor: "#f5f5f5",
                },
                "& .MuiDataGrid-row:hover": {
                  bgcolor: "#f1f1f1",
                },
                "& .Mui-selected": {
                  bgcolor: "#e0e0e0 !important",
                  color: "#000",
                  border: "none",
                },
                "& .Mui-selected:hover": {
                  bgcolor: "#d5d5d5 !important",
                },
              }}
            />
          </Box>
        )}
      </Paper>
      {row && (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Id Proof
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={(theme) => ({
              position: "absolute",
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            })}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Box
              sx={{
                textAlign: "left",
                marginBottom: 2,
                opacity: 1,
                background: "transparent",
                color: "rgb(52, 71, 103)",
                boxShadow: "none",
                width: "400px",
                height: "500px",
                border: "1px solid rgb(0, 0, 0)",
                overflow: "hidden",
              }}
            >
              {renderPreview()}
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
};

export default JobList;
