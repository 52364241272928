import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import RotatingCard from "sections/RotatingCard";
import RotatingCardFront from "sections/RotatingCard/RotatingCardFront";
import RotatingCardBack from "sections/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "sections/InfoCards/DefaultInfoCard";

function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={
                  "https://firebasestorage.googleapis.com/v0/b/a1hometutors-59744.appspot.com/o/results%2Fa1hometutors-rohini-delhi-home-tutors-for-class-vi-4pt3576mxt.avif?alt=media&token=e19c1753-fd24-4447-b9ea-9036bb62a733"
                }
                icon="touch_app"
                title=""
                description=""
              />
              <RotatingCardBack
                image={
                  "https://firebasestorage.googleapis.com/v0/b/a1hometutors-59744.appspot.com/o/results%2Fa1hometutors-rohini-delhi-home-tutors-for-class-vi-4pt3576mxt.avif?alt=media&token=e19c1753-fd24-4447-b9ea-9036bb62a733"
                }
                title="Results"
                description=""
                action={{
                  type: "internal",
                  route: "/results",
                  label: "Results",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                <DefaultInfoCard
                  icon="content_copy"
                  title="Results"
                  description="Our fundamental laxal aim of achieving the zenith of paramount knowledge to spreading it at global segment. Today we must need to co-operate massive enchancing to correlate of enjoying amenities of modern education apparatus. Indeed we intend to emphosis a moral laxity among student in the contemporary era, we endeavour our best to uplifting educaitonal standard by and bound. It is our pledgeful duty to encourage the level of high standard of education dynamic in present in scientific scenerio."
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
