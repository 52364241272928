import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultNavbar from "sections/DefaultNavbar";
import DefaultFooter from "sections/DefaultFooter";
import routes from "routes";
import footerRoutes from "footer.routes";
import bgImage from "assets/images/bg-presentation.webp";
import { Typography } from "@mui/material";

function AboutUs() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "internal",
          route: "/login",
          label: "LOGIN",
          color: "info",
        }}
        sticky
      />
      <MKBox
        minHeight="40vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              About Us
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Grid container>
          <Grid md={3} lg={3} xl={3}></Grid>
          <Grid xs={12} sm={12} md={6} lg={6} xl={6} sx={{ textAlign: "center" }}>
            <h4>Welcome to A-1 Home Tutors</h4>
            <Typography sx={{ fontWeight: 400, fontSize: "1rem" }}>
              A1 Institute is the foremost Eductaional Platform to performing the best ettiquote to
              reforming structural development of Education Volumes. It also believes to prospering
              the educational nurturing so that child can continuing develop his mental calierity,
              mental nurturing, paramount value in his /her cognitive conscions. We also believe to
              promote appendix of huge or Oceanic glance of knowledge with, scientific parbalises.
              In the present acon, A1 Institute Establish fundamental approached to student in
              regard to students mental cliberity.
            </Typography>
            <h5>Result</h5>
            <Typography sx={{ fontWeight: 400, fontSize: "1rem" }}>
              Our fundamental laxal aim of achieving the zenith of paramount knowledge to spreading
              it at global segment. Today we must need to co-operate massive enchancing to correlate
              of enjoying amenities of modern education apparatus. Indeed we intend to emphosis a
              moral laxity among student in the contemporary era, we endeavour our best to uplifting
              educational standard by and bound. It is our pledgeful duty to encourage the level of
              high standard of education dynamic in present in scientfic scenerio.
            </Typography>
          </Grid>
          <Grid md={3} lg={3} xl={3}></Grid>
        </Grid>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default AboutUs;
