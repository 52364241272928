import {
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Drawer,
  FormControl,
  Grid,
  Typography,
  FormHelperText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import Lottie from "lottie-react";
import trash_animation from "components/TrashAnimation/trash_animation.json";
import { useFormik } from "formik";
import * as Yup from "yup";
import { addCampaignImage } from "firestoreService";
import { deletCampaignImage } from "firestoreService";
import { getCampaings } from "firestoreService";
import imageCompression from "browser-image-compression";

const Campaigns = () => {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deteteData, setDeleteData] = useState(null);
  const [render, setRender] = useState(false);
  const [imagePreview, setImagePreview] = useState("/Portrait_Placeholder.png");

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      photo: null,
    },

    validationSchema: Yup.object().shape({
      photo: Yup.mixed()
        .required("Photo is required")
        .test(
          "fileSize",
          "File size is too large (max 100KB)",
          (value) => !value || (value && value.size <= 102400)
        )
        .test(
          "fileType",
          "Unsupported file format (only png, jpg, jpeg, webp)",
          (value) =>
            !value ||
            (value && ["image/png", "image/jpg", "image/jpeg", "image/webp"].includes(value.type))
        ),
    }),

    onSubmit: async (values) => {
      setLoader(true);
      console.log(values);
      await addCampaignImage(values);
      validation.resetForm();
      setImagePreview("/Portrait_Placeholder.png");
      handleClose();
      setLoader(false);
      alert("Added Successfully");
      setRender(!render);
    },
  });

  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      // Check if the file is an image
      const isImage = ["image/png", "image/jpg", "image/jpeg", "image/webp"].includes(file.type);
      if (!isImage) {
        console.error("Unsupported file type. Please upload a valid image.");
        validation.setFieldError(
          "photo",
          "Unsupported file format. Please upload png, jpg, jpeg, or webp."
        );
        return;
      }

      let processedFile = file;

      // Compress image if it exceeds 100KB
      if (file.size > 102400) {
        try {
          const options = {
            maxSizeMB: 0.1, // 0.1 MB = 100 KB
            maxWidthOrHeight: 800, // Optional resizing
            useWebWorker: true,
          };
          processedFile = await imageCompression(file, options);
          console.log("Image compressed successfully:", processedFile);
        } catch (error) {
          console.error("Error compressing image:", error);
          validation.setFieldError("photo", "Error compressing the image. Please try again.");
          return;
        }
      }

      // Set the processed file in Formik
      validation.setFieldValue("photo", processedFile);

      // Generate preview
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(processedFile);
    }
  };

  const handleClose = () => {
    setImagePreview("/Portrait_Placeholder.png");
    validation.resetForm();
    setOpen(false);
  };

  const getCampaignList = async () => {
    setLoader(true);
    const res = await getCampaings();
    console.log(res?.data);
    setData(res?.data);
    setLoader(false);
  };

  useEffect(() => {
    getCampaignList();
  }, [render]);

  const handleDelete = (tutor) => {
    setDeleteModal(true);
    setDeleteData(tutor);
  };

  const handleDeleteClose = () => {
    setDeleteModal(false);
    setDeleteData(null);
  };

  const handleSubmitDelete = async () => {
    setLoader(true);
    await deletCampaignImage(deteteData);
    handleDeleteClose();
    setLoader(false);
    alert("Deleted Successfully");
    setRender(!render);
  };

  return (
    <Box sx={{ p: 3, bgcolor: "#f9f9f9", height: "100vh", maxWidth: "1300px" }}>
      <Box sx={{ backgroundColor: "#fff", p: 2, borderRadius: "10px" }}>
        <Typography
          sx={{
            fontSize: "1.8rem",
            fontWeight: "bold",
            color: "#333",
            mb: 2,
          }}
        >
          Campaings
        </Typography>
      </Box>
      <Box>
        {loader ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 600,
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            <Button
              variant="contained"
              color="primary"
              sx={{
                fontSize: "0.875rem",
                textTransform: "none",
                color: "#fff",
                float: "right",
              }}
              onClick={() => setOpen(true)}
            >
              <AddIcon sx={{ mr: 1 }} /> Add
            </Button>
            <Grid item xs={12} lg={9} sx={{ mt: 2 }}>
              <Grid container spacing={3}>
                {data.map((item, index) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={4} sx={{ mb: 2 }} key={index}>
                    <Card sx={{ borderRadius: "0.75rem", overflow: "hidden", p: 2 }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "center",
                          mr: -2,
                          mb: 1,
                        }}
                      >
                        <DeleteForeverIcon
                          sx={{ mr: 2, color: "red", fontSize: "1rem", cursor: "pointer" }}
                          onClick={() => handleDelete(item)}
                        />
                      </Box>
                      <Box
                        sx={{
                          height: "200px", // Fixed height for uniformity
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={item}
                          alt={`img-${index}`}
                          style={{
                            maxWidth: "100%", // Ensure it doesn't overflow
                            maxHeight: "100%", // Maintain aspect ratio
                            objectFit: "contain", // Show full image without cropping
                          }}
                        />
                      </Box>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Box>
        )}
        <Box sx={{ display: "flex" }}>
          <Backdrop
            open={open}
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1200,
            }}
          />
          <Drawer anchor="right" open={open} onClose={handleClose}>
            <Box sx={{ width: 400 }}>
              <Box className="main_header">
                <Box className="add_lead">{"Add Campaign"}</Box>
                <Box>
                  <CloseIcon className="close" onClick={handleClose} />
                </Box>
              </Box>
              <Box sx={{ p: 2 }}>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  {loader ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Box>
                      <FormControl fullWidth>
                        <Box
                          sx={{
                            textAlign: "left",
                            marginBottom: 2,
                            opacity: 1,
                            background: "transparent",
                            color: "rgb(52, 71, 103)",
                            boxShadow: "none",
                            width: "100px",
                            height: "100px",
                            border: "1px solid rgb(0, 0, 0)",
                          }}
                        >
                          <img
                            src={imagePreview}
                            alt="Uploaded"
                            style={{ width: 100, height: 100 }}
                          />
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              fontWeight: 700,
                              fontSize: "0.875rem",
                            }}
                          >
                            Upload your photo (max size 100KB and formats: png, jpg, jpeg, webp)
                            <span style={{ color: "red", marginLeft: 3 }}>*</span>
                          </Typography>
                          <input
                            type="file"
                            accept=".png,.jpg,.jpeg,.webp"
                            style={
                              validation.touched.photo && validation.errors.photo
                                ? { marginTop: 8, border: "1px solid red" }
                                : { marginTop: 8 }
                            }
                            onChange={handleImageChange}
                          />
                          {validation.touched.photo && validation.errors.photo ? (
                            <FormHelperText sx={{ color: "red" }}>
                              {validation.errors.photo}
                            </FormHelperText>
                          ) : null}
                        </Box>
                      </FormControl>
                      <Divider sx={{ mt: 5, width: "100%" }} />
                      <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        sx={{ mt: 2, color: "#fff" }}
                      >
                        Submit
                      </Button>
                    </Box>
                  )}
                </form>
              </Box>
            </Box>
          </Drawer>
        </Box>
        <Dialog open={deleteModal}>
          <Box
            sx={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              paddingTop: "13px",
              paddingRight: "13px",
            }}
          >
            <CloseIcon sx={{ fontSize: "25px", cursor: "pointer" }} onClick={handleDeleteClose} />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: " 0 30px 30px 30px",
            }}
          >
            <Box sx={{ padding: " 20px 0px" }}>
              <Box sx={{ height: "150px", width: "150px" }}>
                <Lottie animationData={trash_animation} loop={true} />
              </Box>
            </Box>
            <DialogTitle
              sx={{
                textAlign: "center",
                fontWeight: "semi-bold",
                padding: "10px 0px",
              }}
              id="alert-dialog-title"
            >
              {"Do you want to Delete ?"}
            </DialogTitle>
            <DialogActions sx={{ justifyContent: "center", width: "100%" }}>
              <Button
                onClick={handleDeleteClose}
                variant="outlined"
                sx={{
                  color: "#0c203b",
                  borderColor: "#0c203b",
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleSubmitDelete}
                sx={{
                  color: "#FFF",
                }}
              >
                Yes, Delete It!!
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      </Box>
    </Box>
  );
};

export default Campaigns;
