import React from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import ListIcon from "@mui/icons-material/List";
import WorkIcon from "@mui/icons-material/Work";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import DatasetIcon from "@mui/icons-material/Dataset";
import CampaignIcon from "@mui/icons-material/Campaign";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Button, Card, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { signOut } from "firebase/auth";
import { auth } from "../../../firebaseConfig";
import { useNavigate } from "react-router-dom";

const Sidebar = (props) => {
  const { active, setActive } = props;

  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/login");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };
  return (
    <Card
      sx={{
        width: 220,
        background: "#FFF",
        color: "#000",
        p: 2,
        borderTopRightRadius: 2,
        borderBottomRightRadius: 2,
        textAlign: "center",
        height: "100vh",
      }}
    >
      <Typography
        mb={2}
        sx={{
          fontSize: "1.25rem",
          fontWeight: 700,
          color: "#000 !important",
          borderRadius: "100px",
          background: "#fff",
        }}
      >
        A1 Home Tutors
      </Typography>
      <Stack spacing={2} sx={{ textAlign: "left" }}>
        <Button
          variant={active === "tutor" ? "contained" : "text"}
          color={active === "tutor" ? "secondary" : "inherit"}
          sx={{
            fontSize: "0.875rem",
            textTransform: "none",
            color: active === "tutor" ? "#FFF" : "",
            justifyContent: "flex-start",
          }}
          onClick={() => setActive("tutor")}
        >
          <ListIcon sx={{ mr: 1 }} /> Tutors List
        </Button>
        <Button
          variant={active === "job" ? "contained" : "text"}
          color={active === "job" ? "secondary" : "inherit"}
          sx={{
            fontSize: "0.875rem",
            textTransform: "none",
            color: active === "job" ? "#FFF" : "",
            justifyContent: "flex-start",
          }}
          onClick={() => setActive("job")}
        >
          <WorkIcon sx={{ mr: 1 }} /> Job Placements
        </Button>
        <Button
          variant={active === "teacher" ? "contained" : "text"}
          color={active === "teacher" ? "secondary" : "inherit"}
          sx={{
            fontSize: "0.875rem",
            textTransform: "none",
            color: active === "teacher" ? "#FFF" : "",
            justifyContent: "flex-start",
          }}
          onClick={() => setActive("teacher")}
        >
          <PeopleAltIcon sx={{ mr: 1 }} /> Teachers
        </Button>
        <Button
          variant={active === "results" ? "contained" : "text"}
          color={active === "results" ? "secondary" : "inherit"}
          sx={{
            fontSize: "0.875rem",
            textTransform: "none",
            color: active === "results" ? "#FFF" : "",
            justifyContent: "flex-start",
          }}
          onClick={() => setActive("results")}
        >
          <DatasetIcon sx={{ mr: 1 }} /> Results
        </Button>
        <Button
          variant={active === "campaings" ? "contained" : "text"}
          color={active === "campaings" ? "secondary" : "inherit"}
          sx={{
            fontSize: "0.875rem",
            textTransform: "none",
            color: active === "campaings" ? "#FFF" : "",
            justifyContent: "flex-start",
          }}
          onClick={() => setActive("campaings")}
        >
          <CampaignIcon sx={{ mr: 1 }} /> Campaings
        </Button>
        <Button
          variant={active === "youtube" ? "contained" : "text"}
          color={active === "youtube" ? "secondary" : "inherit"}
          sx={{
            fontSize: "0.875rem",
            textTransform: "none",
            color: active === "youtube" ? "#FFF" : "",
            justifyContent: "flex-start",
          }}
          onClick={() => setActive("youtube")}
        >
          <YouTubeIcon sx={{ mr: 1 }} /> Youtube
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{
            fontSize: "0.875rem",
            textTransform: "none",
            position: "absolute",
            bottom: 30,
            left: 30,
            right: 30,
            color: "#fff",
          }}
          onClick={handleLogout}
        >
          <LogoutIcon sx={{ mr: 1 }} /> Logout
        </Button>
      </Stack>
    </Card>
  );
};

Sidebar.propTypes = {
  active: PropTypes.string.isRequired,
  setActive: PropTypes.func.isRequired,
};

export default Sidebar;
