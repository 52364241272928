import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultNavbar from "sections/DefaultNavbar";
import DefaultFooter from "sections/DefaultFooter";
import routes from "routes";
import footerRoutes from "footer.routes";
import bgImage from "assets/images/bg-presentation.webp";
import {
  Box,
  RadioGroup,
  FormControl,
  FormControlLabel,
  TextField,
  Typography,
  Radio,
  Button,
  Divider,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import imageCompression from "browser-image-compression";
import { submitJobData } from "firestoreService";

function JobPlacement() {
  const [submitLoader, setSubmitLoader] = useState(false);
  const [imagePreview, setImagePreview] = useState("/Portrait_Placeholder.png");
  const [idProofPreview, setIdProofPreview] = useState(null);
  const [fileType, setFileType] = useState(null);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      photo: null,
      name: "",
      email: "",
      referral: "",
      age: "",
      gender: "",
      fatherName: "",
      mobileNo: "",
      alternateMobileNo: "",
      experience: "",
      currentAddress: "",
      permanentAddress: "",
      qualification: "",
      prefferedArea: "",
      maritalStatus: "",
      regDate: "",
      idProof: null,
      profile: "",
      salaryExpected: "",
    },

    validationSchema: Yup.object().shape({
      photo: Yup.mixed()
        .required("Photo is required")
        .test(
          "fileSize",
          "File size is too large (max 100KB)",
          (value) => !value || (value && value.size <= 102400)
        )
        .test(
          "fileType",
          "Unsupported file format (only png, jpg, jpeg, webp)",
          (value) =>
            !value ||
            (value && ["image/png", "image/jpg", "image/jpeg", "image/webp"].includes(value.type))
        ),
      idProof: Yup.mixed()
        .required("ID Proof is required")
        .test(
          "fileSize",
          "File size is too large (max 100KB)",
          (value) =>
            !value ||
            (value && ["image/png", "image/jpg", "image/jpeg", "image/webp"].includes(value.type))
        )
        .test(
          "fileType",
          "Unsupported file format (only png, jpg, jpeg, webp, pdf, docs)",
          (value) =>
            !value ||
            (value &&
              ["image/png", "image/jpg", "image/jpeg", "image/webp", "application/pdf"].includes(
                value.type
              ))
        ),
      name: Yup.string().required("Name is required"),
      email: Yup.string().email("Invalid email format").required("Email is required"),
      age: Yup.number().positive("Age must be positive").required("Age is required"),
      gender: Yup.string().required("Gender is required"),
      fatherName: Yup.string().required("Father's name is required"),
      mobileNo: Yup.string()
        .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits")
        .required("Mobile number is required"),
      alternateMobileNo: Yup.string()
        .matches(/^[0-9]{10}$/, "Alternate mobile number must be 10 digits")
        .required("Alternate Mobile number is required"),
      experience: Yup.number()
        .min(0, "Experience must be at least 0")
        .required("Experience is required"),
      currentAddress: Yup.string().required("Current address is required"),
      permanentAddress: Yup.string().required("Permanent address is required"),
      qualification: Yup.string().required("Qualification is required"),
      prefferedArea: Yup.string().required("Preferred area is required"),
      maritalStatus: Yup.string().required("Marital status is required"),
      regDate: Yup.string().required("Date of Registration is required"),
      salaryExpected: Yup.string().required("Salary expected  is required"),
      profile: Yup.string().required("Profile / Job Type is required"),
    }),

    onSubmit: async (values) => {
      console.log(values);
      setSubmitLoader(true);
      await submitJobData(values);
      validation.resetForm();
      setImagePreview("/Portrait_Placeholder.png");
      setIdProofPreview(null);
      setFileType(null);
      alert("Submit Successfully");
      setSubmitLoader(false);
    },
  });

  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      // Check if the file is an image
      const isImage = ["image/png", "image/jpg", "image/jpeg", "image/webp"].includes(file.type);
      if (!isImage) {
        console.error("Unsupported file type. Please upload a valid image.");
        validation.setFieldError(
          "photo",
          "Unsupported file format. Please upload png, jpg, jpeg, or webp."
        );
        return;
      }

      let processedFile = file;

      // Compress image if it exceeds 100KB
      if (file.size > 102400) {
        try {
          const options = {
            maxSizeMB: 0.1, // 0.1 MB = 100 KB
            maxWidthOrHeight: 800, // Optional resizing
            useWebWorker: true,
          };
          processedFile = await imageCompression(file, options);
          console.log("Image compressed successfully:", processedFile);
        } catch (error) {
          console.error("Error compressing image:", error);
          validation.setFieldError("photo", "Error compressing the image. Please try again.");
          return;
        }
      }

      // Set the processed file in Formik
      validation.setFieldValue("photo", processedFile);

      // Generate preview
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(processedFile);
    }
  };

  const handleIdproofChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      // Check file type
      const isImage = ["image/png", "image/jpg", "image/jpeg", "image/webp"].includes(file.type);

      let processedFile = file;

      // Compress image if it exceeds 100KB
      if (isImage && file.size > 102400) {
        try {
          const options = {
            maxSizeMB: 0.1, // 0.1 MB = 100 KB
            maxWidthOrHeight: 800, // Optional resizing
            useWebWorker: true,
          };
          processedFile = await imageCompression(file, options);
        } catch (error) {
          console.error("Error compressing image:", error);
        }
      }

      // Set file in Formik
      validation.setFieldValue("idProof", processedFile);

      // Generate preview
      const reader = new FileReader();
      reader.onload = () => {
        setIdProofPreview(reader.result);
        setFileType(file.type);
      };
      reader.readAsDataURL(processedFile);
    }
  };

  const renderPreview = () => {
    if (fileType?.includes("image")) {
      return <img src={idProofPreview} alt="ID Proof" style={{ width: "100%", height: "100%" }} />;
    } else if (fileType === "application/pdf") {
      return (
        <iframe
          src={idProofPreview}
          title="PDF Preview"
          style={{ width: "100%", height: "100%" }}
        />
      );
    } else {
      return (
        <Typography sx={{ color: "gray", textAlign: "center", padding: 2 }}>
          Cannot preview this file type. Download to view.
        </Typography>
      );
    }
  };

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "internal",
          route: "/login",
          label: "LOGIN",
          color: "info",
        }}
        sticky
      />
      <MKBox
        minHeight="40vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Job Placement
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        {submitLoader ? (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ padding: 4 }}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Grid container spacing={2}>
                {/* Photo Upload */}
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Box
                      sx={{
                        textAlign: "left",
                        marginBottom: 2,
                        opacity: 1,
                        background: "transparent",
                        color: "rgb(52, 71, 103)",
                        boxShadow: "none",
                        width: "200px",
                        height: "200px",
                        border: "1px solid rgb(0, 0, 0)",
                      }}
                    >
                      <img src={imagePreview} alt="Uploaded" style={{ width: 200, height: 200 }} />
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: "0.875rem",
                        }}
                      >
                        Upload your photo (max size 100KB and formats: png, jpg, jpeg, webp)
                        <span style={{ color: "red", marginLeft: 3 }}>*</span>
                      </Typography>
                      <input
                        type="file"
                        accept=".png,.jpg,.jpeg,.webp"
                        style={
                          validation.touched.photo && validation.errors.photo
                            ? { marginTop: 8, border: "1px solid red" }
                            : { marginTop: 8 }
                        }
                        onChange={handleImageChange}
                      />
                      {validation.touched.photo && validation.errors.photo ? (
                        <FormHelperText sx={{ color: "red" }}>
                          {validation.errors.photo}
                        </FormHelperText>
                      ) : null}
                    </Box>
                  </FormControl>
                </Grid>

                {/* Name */}
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <Typography sx={{ fontSize: "0.875rem", fontWeight: 700 }}>
                      Name<span style={{ color: "red", marginLeft: 3 }}>*</span>
                    </Typography>
                    <TextField
                      size="small"
                      name="name"
                      type="text"
                      value={validation.values.name}
                      onChange={validation.handleChange}
                      error={validation.touched.name && validation.errors.name}
                    />
                    {validation.touched.name && validation.errors.name ? (
                      <FormHelperText sx={{ color: "red" }}>
                        {validation.errors.name}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>

                {/* Email */}
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <Typography sx={{ fontSize: "0.875rem", fontWeight: 700 }}>
                      Email<span style={{ color: "red", marginLeft: 3 }}>*</span>
                    </Typography>
                    <TextField
                      size="small"
                      name="email"
                      type="text"
                      value={validation.values.email}
                      onChange={validation.handleChange}
                      error={validation.touched.email && validation.errors.email}
                    />
                    {validation.touched.email && validation.errors.email ? (
                      <FormHelperText sx={{ color: "red" }}>
                        {validation.errors.email}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>

                {/* Referral */}
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <Typography sx={{ fontSize: "0.875rem", fontWeight: 700 }}>Referral</Typography>
                    <TextField
                      size="small"
                      name="email"
                      type="text"
                      value={validation.values.referral}
                      onChange={validation.handleChange}
                      error={validation.touched.referral && validation.errors.referral}
                    />
                    {validation.touched.referral && validation.errors.referral ? (
                      <FormHelperText sx={{ color: "red" }}>
                        {validation.errors.referral}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>

                {/* Age */}
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <Typography sx={{ fontSize: "0.875rem", fontWeight: 700 }}>
                      Age<span style={{ color: "red", marginLeft: 3 }}>*</span>
                    </Typography>
                    <TextField
                      size="small"
                      name="age"
                      type="text"
                      value={validation.values.age}
                      onChange={validation.handleChange}
                      error={validation.touched.age && validation.errors.age}
                    />
                    {validation.touched.age && validation.errors.age ? (
                      <FormHelperText sx={{ color: "red" }}>{validation.errors.age}</FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>

                {/* Gender */}
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <Typography sx={{ fontSize: "0.875rem", fontWeight: 700 }}>
                      Gender<span style={{ color: "red", marginLeft: 3 }}>*</span>
                    </Typography>
                    <RadioGroup
                      row
                      name="gender"
                      value={validation.values.gender}
                      onChange={validation.handleChange}
                      error={validation.touched.gender && validation.errors.gender}
                    >
                      <FormControlLabel value="female" control={<Radio />} label="Female" />
                      <FormControlLabel value="male" control={<Radio />} label="Male" />
                    </RadioGroup>
                    {validation.touched.gender && validation.errors.gender ? (
                      <FormHelperText sx={{ color: "red" }}>
                        {validation.errors.gender}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>

                {/* Father's Name */}
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <Typography sx={{ fontSize: "0.875rem", fontWeight: 700 }}>
                      Father&apos;s Name<span style={{ color: "red", marginLeft: 3 }}>*</span>
                    </Typography>
                    <TextField
                      size="small"
                      name="fatherName"
                      type="text"
                      value={validation.values.fatherName}
                      onChange={validation.handleChange}
                      error={validation.touched.fatherName && validation.errors.fatherName}
                    />
                    {validation.touched.fatherName && validation.errors.fatherName ? (
                      <FormHelperText sx={{ color: "red" }}>
                        {validation.errors.fatherName}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>

                {/* Mobile Number */}
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <Typography sx={{ fontSize: "0.875rem", fontWeight: 700 }}>
                      Mobile Number<span style={{ color: "red", marginLeft: 3 }}>*</span>
                    </Typography>
                    <TextField
                      size="small"
                      name="mobileNo"
                      type="text"
                      value={validation.values.mobileNo}
                      onChange={validation.handleChange}
                      error={validation.touched.mobileNo && validation.errors.mobileNo}
                    />
                    {validation.touched.mobileNo && validation.errors.mobileNo ? (
                      <FormHelperText sx={{ color: "red" }}>
                        {validation.errors.mobileNo}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>

                {/* Alternate Mobile Number */}
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <Typography sx={{ fontSize: "0.875rem", fontWeight: 700 }}>
                      Alternate Mobile Number<span style={{ color: "red", marginLeft: 3 }}>*</span>
                    </Typography>
                    <TextField
                      size="small"
                      name="alternateMobileNo"
                      type="text"
                      value={validation.values.alternateMobileNo}
                      onChange={validation.handleChange}
                      error={
                        validation.touched.alternateMobileNo && validation.errors.alternateMobileNo
                      }
                    />
                    {validation.touched.alternateMobileNo && validation.errors.alternateMobileNo ? (
                      <FormHelperText sx={{ color: "red" }}>
                        {validation.errors.alternateMobileNo}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>

                {/* Experience (in years) */}
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <Typography sx={{ fontSize: "0.875rem", fontWeight: 700 }}>
                      Experience (in years)<span style={{ color: "red", marginLeft: 3 }}>*</span>
                    </Typography>
                    <TextField
                      size="small"
                      name="experience"
                      type="text"
                      value={validation.values.experience}
                      onChange={validation.handleChange}
                      error={validation.touched.experience && validation.errors.experience}
                    />
                    {validation.touched.experience && validation.errors.experience ? (
                      <FormHelperText sx={{ color: "red" }}>
                        {validation.errors.experience}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>

                {/* Current Address */}
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <Typography sx={{ fontSize: "0.875rem", fontWeight: 700 }}>
                      Current Address<span style={{ color: "red", marginLeft: 3 }}>*</span>
                    </Typography>
                    <TextField
                      size="small"
                      name="currentAddress"
                      type="text"
                      value={validation.values.currentAddress}
                      onChange={validation.handleChange}
                      error={validation.touched.currentAddress && validation.errors.currentAddress}
                    />
                    {validation.touched.currentAddress && validation.errors.currentAddress ? (
                      <FormHelperText sx={{ color: "red" }}>
                        {validation.errors.currentAddress}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>

                {/* Permanent Address*/}
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <Typography sx={{ fontSize: "0.875rem", fontWeight: 700 }}>
                      Permanent Address<span style={{ color: "red", marginLeft: 3 }}>*</span>
                    </Typography>
                    <TextField
                      size="small"
                      name="permanentAddress"
                      type="text"
                      value={validation.values.permanentAddress}
                      onChange={validation.handleChange}
                      error={
                        validation.touched.permanentAddress && validation.errors.permanentAddress
                      }
                    />
                    {validation.touched.permanentAddress && validation.errors.permanentAddress ? (
                      <FormHelperText sx={{ color: "red" }}>
                        {validation.errors.permanentAddress}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>

                {/* Qualification*/}
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <Typography sx={{ fontSize: "0.875rem", fontWeight: 700 }}>
                      Qualification<span style={{ color: "red", marginLeft: 3 }}>*</span>
                    </Typography>
                    <TextField
                      size="small"
                      name="qualification"
                      type="text"
                      value={validation.values.qualification}
                      onChange={validation.handleChange}
                      error={validation.touched.qualification && validation.errors.qualification}
                    />
                    {validation.touched.qualification && validation.errors.qualification ? (
                      <FormHelperText sx={{ color: "red" }}>
                        {validation.errors.qualification}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>

                {/* Preffered Area*/}
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <Typography sx={{ fontSize: "0.875rem", fontWeight: 700 }}>
                      Preffered Area<span style={{ color: "red", marginLeft: 3 }}>*</span>
                    </Typography>
                    <TextField
                      size="small"
                      name="prefferedArea"
                      type="text"
                      value={validation.values.prefferedArea}
                      onChange={validation.handleChange}
                      error={validation.touched.prefferedArea && validation.errors.prefferedArea}
                    />
                    {validation.touched.prefferedArea && validation.errors.prefferedArea ? (
                      <FormHelperText sx={{ color: "red" }}>
                        {validation.errors.prefferedArea}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>

                {/* Marital Status */}
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth>
                    <Typography sx={{ fontSize: "0.875rem", fontWeight: 700 }}>
                      Marital Status<span style={{ color: "red", marginLeft: 3 }}>*</span>
                    </Typography>
                    <RadioGroup
                      row
                      name="maritalStatus"
                      value={validation.values.maritalStatus}
                      onChange={validation.handleChange}
                      error={validation.touched.maritalStatus && validation.errors.maritalStatus}
                    >
                      <FormControlLabel value="married" control={<Radio />} label="Married" />
                      <FormControlLabel value="unmarried" control={<Radio />} label="Unmarried" />
                    </RadioGroup>
                    {validation.touched.maritalStatus && validation.errors.maritalStatus ? (
                      <FormHelperText sx={{ color: "red" }}>
                        {validation.errors.maritalStatus}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>

                {/* Date of Registration */}
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth>
                    <Typography sx={{ fontSize: "0.875rem", fontWeight: 700 }}>
                      Date of Registration<span style={{ color: "red", marginLeft: 3 }}>*</span>
                    </Typography>
                    <TextField
                      size="small"
                      name="regDate"
                      type="date"
                      value={validation.values.regDate}
                      onChange={validation.handleChange}
                      error={validation.touched.regDate && validation.errors.regDate}
                    />
                    {validation.touched.regDate && validation.errors.regDate ? (
                      <FormHelperText sx={{ color: "red" }}>
                        {validation.errors.regDate}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>

                {/* Salary expected (in thousand/month) */}
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth>
                    <Typography sx={{ fontSize: "0.875rem", fontWeight: 700 }}>
                      Salary expected (in thousand/month)
                      <span style={{ color: "red", marginLeft: 3 }}>*</span>
                    </Typography>
                    <TextField
                      size="small"
                      name="salaryExpected"
                      type="text"
                      value={validation.values.salaryExpected}
                      onChange={validation.handleChange}
                      error={validation.touched.salaryExpected && validation.errors.salaryExpected}
                    />
                    {validation.touched.salaryExpected && validation.errors.salaryExpected ? (
                      <FormHelperText sx={{ color: "red" }}>
                        {validation.errors.salaryExpected}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>

                {/* Profile / Job Type */}
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth>
                    <Typography sx={{ fontSize: "0.875rem", fontWeight: 700 }}>
                      Profile / Job Type<span style={{ color: "red", marginLeft: 3 }}>*</span>
                    </Typography>
                    <TextField
                      size="small"
                      name="profile"
                      type="text"
                      value={validation.values.profile}
                      onChange={validation.handleChange}
                      error={validation.touched.profile && validation.errors.profile}
                    />
                    {validation.touched.profile && validation.errors.profile ? (
                      <FormHelperText sx={{ color: "red" }}>
                        {validation.errors.profile}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>

                {/* id Proof Upload */}
                <Grid item xs={12} sm={12}>
                  <FormControl fullWidth>
                    <Box>
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: "0.875rem",
                        }}
                      >
                        Upload Id proof (max size 100KB and formats: png, jpg, jpeg, webp, pdf,
                        docs)
                        <span style={{ color: "red", marginLeft: 3 }}>*</span>
                      </Typography>
                      <input
                        type="file"
                        accept=".png,.jpg,.jpeg,.webp,.pdf,.docs"
                        style={
                          validation.touched.idProof && validation.errors.idProof
                            ? { marginTop: 8, border: "1px solid red" }
                            : { marginTop: 8 }
                        }
                        onChange={handleIdproofChange}
                      />
                      {validation.touched.idProof && validation.errors.idProof ? (
                        <FormHelperText sx={{ color: "red" }}>
                          {validation.errors.idProof}
                        </FormHelperText>
                      ) : null}
                    </Box>
                    {idProofPreview && (
                      <Box
                        sx={{
                          textAlign: "left",
                          marginBottom: 2,
                          opacity: 1,
                          background: "transparent",
                          color: "rgb(52, 71, 103)",
                          boxShadow: "none",
                          width: "400px",
                          height: "500px",
                          border: "1px solid rgb(0, 0, 0)",
                          overflow: "hidden",
                        }}
                      >
                        {idProofPreview ? (
                          renderPreview()
                        ) : (
                          <Typography>Preview will appear here</Typography>
                        )}
                      </Box>
                    )}
                  </FormControl>
                </Grid>

                <Divider sx={{ mt: 5, width: "100%" }} />
                {/* Submit Button */}
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography>
                    Note : Fields Marked with asterisk(
                    <span style={{ color: "red", marginLeft: 3 }}>*</span>) are mandatory
                  </Typography>
                  <Button type="submit" variant="contained" sx={{ mt: 2, color: "#fff" }}>
                    Submit
                  </Button>
                </Box>
              </Grid>
            </form>
          </Box>
        )}
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default JobPlacement;
